import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const token = atom({
  key: 'token',
  default: {
    accessToken: ''
  },
  effects_UNSTABLE: [persistAtom],
});

export const isLoading = atom<boolean>({
  key: 'isLoading',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const userInfo = atom({
  key: 'userInfo',
  default: {
    userId: '',
    userNm: '',
    userEmail: '',
    userTypeCd: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const locale = atom({
  key: 'locale',
  default: 'ko',
  effects_UNSTABLE: [persistAtom],
});